import axios, { AxiosRequestConfig } from 'axios';
import { AllTransactionResponse, KundeData, KundeSalgData, SalgVarerData, YearlySalesData } from '../models/NV01SALG';
import CSharpUtils from './CSharpUtils';
import Utils from './Utils';

export const fetchAllTransactions = (

  

  firmaNr: number,
  alfa: string,
  artikkel: string,
  page: number,
  pageSize: number
) => {
  const config: AxiosRequestConfig = {
    method: 'get',
    url: `${`${Utils.URL_ADDRESS}${Utils.DOTNET_SERVICE}`}/product/transactions`,
    params: {
      alpha: alfa,
      number: artikkel,
      page,
      pageSize,
      companyId: firmaNr,
    },
    headers: {
      accept: 'application/json',
      Authorization: `Bearer ${CSharpUtils.Get_Token}`,
    },
    responseType: 'json',
    timeout: 10000,
  };

  return axios.request<AllTransactionResponse[]>(config)
    .then(response => {
      if (!response.data || response.data.length === 0) {
        throw new Error('Fant ikke data');
      }
      const Transdata: AllTransactionResponse[] | null = response.data;
      const data = Object.values(Transdata);
      return data[1];
    })

};

export const fetchSalgData4AAR = (
  firmaNr: number,
  alfa: string,
  artikkle: string
) => {
  const config: AxiosRequestConfig = {
    method: 'get',
    url: `${`${Utils.URL_ADDRESS}${Utils.DOTNET_SERVICE}`}/product/sales/monthly`,
    params: {
      alpha: alfa,
      number: artikkle,
      companyId: firmaNr,
      page: 1,
      pageSize: 20,
    },
    headers: {
      accept: 'application/json',
      Authorization: `Bearer ${CSharpUtils.Get_Token}`,
    },
    responseType: 'json',
    timeout: 10000,
  };

  return axios.request<YearlySalesData>(config)
    .then(response => {
      const data = Object.values(response.data);
      return data[2];
    })

};
function convertObjectToArray(obj) {
  return Object.values(obj).map(value => {
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      // Recursively convert nested objects to arrays
      return convertObjectToArray(value);
    }
    return value;
  });
}
export const fetchSalgVareData = (
  firmaNr: number,
  alfa: string,
  artikkel: string
) => {
  const config: AxiosRequestConfig = {
    method: 'get',
    url: `${`${Utils.URL_ADDRESS}${Utils.DOTNET_SERVICE}`}/product`,
    params: {
      alpha: alfa,
      number: artikkel,
      companyId: firmaNr,
    },
    headers: {
      accept: 'application/json',
      Authorization: `Bearer ${CSharpUtils.Get_Token}`,
    },
    responseType: 'json',
    timeout: 10000,
  };

  return axios.request<SalgVarerData>(config)
    .then(response => {
      const data = convertObjectToArray(response.data);
      // console.log('Varer Response: ', [response.data]);
      return [response.data];
    })
};
export const fetchCustomerData = (
  kundenummer: string,
  year: string,
  firmaid: number
) => {
  const config: AxiosRequestConfig = {
    method: 'get',
    url: `${`${Utils.URL_ADDRESS}${Utils.DOTNET_SERVICE}`}/product/transactions/count`,
    params: {
      customerId: kundenummer,
      years: year,
      companyId: firmaid,
    },
    headers: {
      accept: 'application/json',
      Authorization: `Bearer ${CSharpUtils.Get_Token}`,
    },
    responseType: 'json',
    timeout: 10000,
  };
  return axios.request<KundeSalgData[]>(config)
    .then(response => {
      const data = response.data;
      if (!data || data.length === 0) {
        throw new Error('Ingen data funnet for denne kunden');
      }
      return data.slice(0, 100); // Return only the first 100 lines
    })

};
export const fetchKundeData = (firmaid: number, kundeNummer?: string, kundeNavn?: string) => {
  const config: AxiosRequestConfig = {
    method: "get",
    url: `${`${Utils.URL_ADDRESS}${Utils.DOTNET_SERVICE}`}/customers/search`,
    params: {
      numberSearch: kundeNummer,
      nameSearch: kundeNavn,
      companyId: firmaid,
    },
    headers: {
      accept: 'application/json',
      Authorization: `Bearer ${CSharpUtils.Get_Token}`,
    },
    responseType: "json",
    responseEncoding: "utf8",
    timeout: 10000, // Optional timeout if required
  };

  return axios.request<KundeData[]>(config)
    .then(response => {
      return response.data.slice(0, 10);
    })

};


