import styled from '@emotion/styled';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Theme, Toolbar } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import C from '../felles/UtilsCSS';
import UISize from '../models/UISize';
import * as actions from '../store/actions/index';
import { AppState } from '../store/rootReducer';
import LPLocationPath from './LPLocationPath';
import LPNavigationProfile from "./LPNavProfile";

const useStyles = makeStyles()((t: Theme) => ({
  appBar: {
    paddingTop: 0,
    backgroundColor: 'transparent',
    height: 50, zIndex: 2000,
    transition: t.transitions.create(['margin', 'width'], {
      easing: t.transitions.easing.sharp,
      duration: t.transitions.duration.leavingScreen,
    }),
    [C.r(t, C.XXS, C.XXX)]: { height: 50 },
    [C.r(t, C.XS, C.XSX)]: { height: 50 },
    [C.r(t, C.SM, C.SMX)]: { height: 50 },
    [C.r(t, C.MD, C.MDX)]: { height: 60 },
    [C.r(t, C.LG, C.LGX)]: { height: 70 },
    [C.r(t, C.XL, C.XLX)]: { height: 70 },
    [C.r(t, C.XXL, C.YLX)]: { height: 70 },
    [C.IPAD_10]: { height: 0 }, [C.SAMSUNG]: { height: 0 },
  },
  appBarShift: {
    width: `calc(100% - ${332}px)`, marginLeft: 335,
    transition: t.transitions.create(['margin', 'width'], {
      easing: t.transitions.easing.easeOut, duration: t.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: -20, marginRight: t.spacing(1), fontSize: '2em', zIndex: 2000,
    [C.r(t, C.XXS, C.XXX)]: { marginTop: -10, color: '#fff' },
    [C.r(t, C.XS, C.XSX)]: { marginTop: 0, color: '#171b3d' },
    [C.r(t, C.SM, C.SMX)]: { marginTop: 0, color: '#171b3d' },
    [C.r(t, C.MD, C.MDX)]: { marginTop: 10, color: '#fff' },
    [C.r(t, C.LG, C.LGX)]: { marginTop: 10, color: '#fff' },
    [C.r(t, C.XL, C.XLX)]: { marginTop: 10, color: '#fff' },
    [C.r(t, C.XXL, C.YLX)]: { marginTop: 10, color: '#fff' },
    [C.IPAD_10]: { marginTop: 15, marginLeft: -15, color: '#2e2d2c' },
    [C.SAMSUNG]: { marginTop: 10, marginLeft: -19, color: '#2e2d2c' },
  },
  hide: { display: 'none' },
  lowerM: { position: 'fixed', color: '#333', right: '20px',  top: '4px' },
})
);

const StyledToolbar = styled(Toolbar)({
  padding: 0,
  margin: 0,
  color: 'rgba(0, 0, 0, 0.87)',
  position: 'sticky',
  fontSize: '1em',
  [C.IPAD_10]: { minHeight: 40, },
});

export default function LPAppBar(props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const screenSize: UISize = useSelector((state) => (state as AppState).ui.screenSize);
  const drawerOpenState: boolean = useSelector((state) => (state as AppState).ui.isDrawerOpened);
  const button: boolean = useSelector((state) => (state as AppState).ui.isSandwichButtonTurnedOn);
  //
  useEffect(() => {
    dispatch(actions.setDrawerState(!(screenSize.width > 0 && screenSize.width < 1200)));
  }, [dispatch, screenSize]);
  //
  const handleDrawerToggle = () => {
    dispatch(actions.setSandwichButtonStateClicked(!button));
  };
  //
  return (
    <AppBar elevation={0} position="fixed"
      className={clsx(classes.appBar, { [classes.appBarShift]: button ? true : drawerOpenState })}>
      <StyledToolbar>
        {props.ismenuvisible ? <IconButton
          color="inherit"
          aria-label="drawerOpenState drawer"
          onClick={handleDrawerToggle}
          edge="start"
          className={clsx(classes.menuButton, drawerOpenState /* && classes.hide*/)}
        >
          {!drawerOpenState ? <MenuIcon style={{ fontSize: 30, zIndex: 2000 }} /> : []}
        </IconButton> : null} <LPLocationPath />
        <div className={classes.lowerM}>
          <LPNavigationProfile />
        </div>
      </StyledToolbar>
    </AppBar>
  );
}