import { NV03Firma, NV03Headers } from "../../models/NV03Vare";
import pageImpl from "../../models/PageImpl";
import * as actionTypesNV03 from "./actionTypesNV03";

//
export const NV03VALFListeGetStart = () => {
  return { type: actionTypesNV03.NV03_VALF_LIST_GET_INIT };
};

//
export const NV03VALFListeGet = (formData: FormData) => {
  return { type: actionTypesNV03.NV03_VALF_LIST_GET, formData };
};

//
export const NV03VALFListeGetSuccess = (payload: pageImpl) => {
  return { type: actionTypesNV03.NV03_VALF_LIST_GET_SUCCESS, payload };
};

//
export const NV03VALFListeGetFailed = (error: any) => {
  return { type: actionTypesNV03.NV03_VALF_LIST_GET_FAILED, error };
};

//
export const NV03VALFLabelGetSuccess = (payload: NV03Firma) => {
  return { type: actionTypesNV03.NV03_VALF_LABEL_GET_SUCCESS, payload };
};

//
export const NV03VALFLAbelGetFailed = (error: any) => {
  return { type: actionTypesNV03.NV03_VALF_LABEL_GET_FAILED, error };
};


export const NV03ResetComponentStore = () => {
  return { type: actionTypesNV03.NV03_RESET_COMPONENT_STORE };
};


export const NV03UpdateColumnHeaderList = (list: NV03Headers[]) => {
  return { type: actionTypesNV03.NV03_SET_COLUMN_HEADER_LIST, list };
};


export const setIsVeilPrisCheckboxState = (payload: any) => {
  return { type: actionTypesNV03.NV03_SET_VEILPRIS_CHECKBOX_STATE, payload };
};

export const setIsVisAlleLagerCheckboxState = (payload: any) => {
  return { type: actionTypesNV03.NV03_SET_VISALLELAGER_CHECKBOX_STATE, payload };
};

export const setIsBeholdningCheckboxState = (payload: any) => {
  return { type: actionTypesNV03.NV03_SET_BEHOLDNING_CHECKBOX_STATE, payload };
};
