import { Button, Tooltip } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
    vitecBtRed: {
        display: 'inline-block',
        color: '#fff',
        borderRadius: 3,
        background: '#d30535',
        boxShadow: '0 0 5px -1px rgba(0,0,0,0.2)',
        cursor: 'pointer',
        verticalAlign: 'middle',
        width: '100px',
        padding: '5px',
        marginTop: '13px',
        textAlign: 'center',
        '&:hover': {
            background: '#961932',
            color: 'white',
            cursor: 'pointer',
        },
        '&:active': {
            color: 'white',
            background: '#cd5a6e',
        },
        '&:disabled': {
            color: 'white',
            background: '#a9a9a9',  // Darker grey for disabled state
        },
    },
    vitecBtGreen: {
        display: 'inline-block',
        color: '#fff',
        borderRadius: 3,
        background: '#1d6f42',
        boxShadow: '0 0 5px -1px rgba(0,0,0,0.2)',
        cursor: 'pointer',
        verticalAlign: 'middle',
        width: '100px',
        padding: '5px',
        marginTop: '13px',
        textAlign: 'center',
        '&:hover': {
            background: '#269c5b',
            color: 'white',
            cursor: 'pointer',
        },
        '&:active': {
            color: 'white',
            background: '#1bc266',
        },
        '&:disabled': {
            color: 'white',
            background: '#a9a9a9',  // Darker grey for disabled state
        },
    },
    customTooltip: { background: '#cd5a6e', fontSize: 15, fontWeight: 100, color: 'white', margin: 0, marginTop: 10, marginLeft: -5, },
    customArrow: { color: '#cd5a6e', },
})
);

export default function ButtonVitec(props) {
    const { classes } = useStyles();

    //
    return (<>
        <Tooltip classes={{
            tooltip: classes.customTooltip,
            arrow: classes.customArrow
        }} title={props._tooltip} placement={"top"} arrow>
            <Button type={props && props._type ? props._type : 'button'}
                className={props && props._color?.toLowerCase() === 'green' ? classes.vitecBtGreen : classes.vitecBtRed}
                onClick={e => (typeof props._onclick === 'function' ? props._onclick(e) : null)}
                disabled={props.disabled}

                style={{
                    fontSize: props._fontsize,
                    fontFamily: 'roboto',
                    lineHeight: 1.5,
                    fontWeight: 450,
                    width: props._width, height: props._height, margin: props._padding,
                    boxShadow: '4px 4px 6px rgba(0, 0, 0, 0.2)',
                }}
                name={props._name}
                value={props._value}
            >{props._label}</Button>
        </Tooltip>
    </>
    );
}
