import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Container,
  Paper,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { AppState } from '../../../store/rootReducer';
import * as actionsNV03 from '../../../store/actions/indexNV03';
import Heading from '../NO01/Heading';
import SearchFields from './components/InputFields';
import Header from './components/Header';
import YearlySales from './YearlySales';
import GridView from './GridView';
import { YearOptions } from './components/YearOptions';
import VarerDetails from './components/VarerDetail';
import { PaginationControls } from './components/Pagination';
import {
  fetchSalgDataRequest,
  fetchSalgVareDataRequest,
  fetchKundeSalgDataRequest,
  fetchKundeDataRequest,
  fetchTRANSAKSJONERRequest,
  clearData
} from '../../../store/actions/NV01.actions';
import CSharpUtils, { validateKundenummer, validateAlfa } from '../../../felles/CSharpUtils';
import { KundeData } from '../../../models/NV01SALG';

// Define the styles for the component
const useStyles = makeStyles()(() => ({
  root: { margin: 20, fontSize: '1em !important', color: '#fff' },
  container: {
    height: `calc(100vh - 82px)`,
    border: 'none',
  },
}));

const Vars = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    // Disable scroll on mount
    document.body.style.overflow = 'hidden';
    
    // Re-enable scroll on unmount
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  // Redux states
  const { Salg, SalgVarer, KundeData, KundeSalg, Transaction, error: reduxError } = useSelector((state: AppState) => state.NV01SALG);

  // Local states
  const [error, setError] = useState<string | null>(null);
  const [kundenummerInput, setKundenummerInput] = useState<string>('');
  const [isExportDisabled, setIsExportDisabled] = useState(true);
  const [searchedKundenummer, setSearchedKundenummer] = useState<string>('');
  const [alfa, setAlfa] = useState<string>('');
  const [artikkel, setArtikkel] = useState<string>('');
  const [varegruppe, setVaregruppe] = useState<string>('');
  const [salgDataType, setSalgDataType] = useState<string>('customerData');
  const [showYearOptions, setShowYearOptions] = useState(false);
  const [page, setPage] = useState<number>(5);
  const [pageSize, setPageSize] = useState<number>(100);
  const [sisteSalgData, setSisteSalgData] = useState('11.08.2024');
  const [selectedYears, setSelectedYears] = useState<{ [key: number]: boolean }>({
    2023: false,
    2024: true,
  });
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [suggestedCustomers, setSuggestedCustomers] = useState<KundeData[]>([]);

  const kundenummerRef = useRef<HTMLInputElement>(null);



  useEffect(() => {
    // Enable/disable export button based on data availability
    if ((KundeSalg && KundeSalg.length > 0) || (Transaction && Transaction.length > 0)) {
      setIsExportDisabled(false);
    } else {
      setIsExportDisabled(true);
    }
  }, [KundeSalg, Transaction]);

  useEffect(() => {
    // Fetch suggested customers based on input
    if (kundenummerInput !== '') {
      fetchSuggestedCustomers();
    } else {
      setSuggestedCustomers([]);
    }
  }, [kundenummerInput]);

  const fetchSuggestedCustomers = useCallback(() => {
    setError('');

    if (!kundenummerInput) {
      setError('Please enter a valid customer number.');
      return;
    }

    const isAlphabetic = /^[A-Za-z]+$/.test(kundenummerInput);
    const kundenavn = isAlphabetic ? kundenummerInput : '';

    if (isAlphabetic) {
      dispatch(fetchKundeDataRequest(CSharpUtils.T_companyID, '', kundenavn));
    } else {
      dispatch(fetchKundeDataRequest(CSharpUtils.T_companyID, kundenummerInput, kundenavn));
    }

    setSalgDataType('customerData');
  }, [kundenummerInput, dispatch]);

  const handleError = (err: unknown) => {
    if (err instanceof Error) {
      setError(err.message);
    } else {
      setError('An unknown error occurred.');
    }
  };

  const fetchTransactions = useCallback((page = 1, pageSize = 500) => {
    if (isNaN(page) || page <= 0) {
      page = 1;
    }

    if (!validateAlfa(alfa)) {
      setError('Enter Alfa');
      return;
    }
    setShowYearOptions(false);

    dispatch(fetchTRANSAKSJONERRequest(CSharpUtils.T_companyID, alfa, artikkel, page, pageSize));
    dispatch(fetchSalgVareDataRequest(CSharpUtils.T_companyID, alfa, artikkel));
    setSalgDataType('transactionData');
  }, [alfa, artikkel, dispatch]);

  const handleLoadMoreTransactions = useCallback(() => {
    const nextPage = page + 1;
    fetchTransactions(nextPage, pageSize);
    setPage(nextPage);
  }, [page, pageSize, fetchTransactions]);

  const handleKundeTopp50Click = useCallback(() => {
    dispatch(clearData());

    if (!validateKundenummer(searchedKundenummer)) {
      setError('Enter 6 siffer Kundenummer');
      return;
    }

    try {
      setShowYearOptions(true);

      const years = Object.keys(selectedYears)
        .filter((year) => selectedYears[parseInt(year, 10)])
        .map((year) => year.slice(-2))
        .join(',');

      dispatch(fetchKundeSalgDataRequest(searchedKundenummer, years, CSharpUtils.T_companyID));
      setSalgDataType('customerData');
    } catch (err) {
      handleError(err);
    }
  }, [searchedKundenummer, selectedYears, dispatch]);

  const handleHentSalgClick = useCallback(() => {
    dispatch(clearData());

    if (!validateAlfa(alfa)) {
      setError('Enter Alfa');
      return;
    }
    setShowYearOptions(false);
    dispatch(fetchSalgDataRequest(CSharpUtils.T_companyID, alfa, artikkel));
    dispatch(fetchSalgVareDataRequest(CSharpUtils.T_companyID, alfa, artikkel));
    setSalgDataType('salgData');
  }, [alfa, artikkel, dispatch]);

  useEffect(() => {
    if (kundenummerInput && kundenummerInput.length === 6) {
      setSearchedKundenummer(kundenummerInput);
    }
  }, [kundenummerInput]);

  const exportToCSV = () => {
    const dataToExport = salgDataType === 'customerData' ? KundeSalg : Transaction;
    if (!dataToExport || dataToExport.length === 0) {
      setError('No data available to export.');
      return;
    }

    const headers = salgDataType === 'transactionData'
      ? ['Dato', 'Tid', 'Tekst', 'Ref.nr', 'Kundenr', 'Antall', 'Ident', 'Sign', 'Lager', 'Lokasjon']
      : ['Kundenummer', 'Antall', 'Alfa', 'Artikkel', 'Varebetegnelse', 'Varegruppe'];

    const csvContent = [
      headers.join(';'),
      ...dataToExport.map((row) => {
        if (row.count !== undefined && row.sign !== undefined) {
          row.count = `${row.sign === '-' ? '-' : ''}${Math.abs(row.count)}`;
        }

        const rowData = Object.keys(row)
          .filter(key => key !== 'sign')
          .map(key => key === 'edb_number' ? searchedKundenummer : row[key]);

        return rowData.join(';');
      })
    ].join('\n');

    // Adding UTF-8 BOM to handle special characters like ���
    const utf8Bom = '\uFEFF';
    const fullCsvContent = utf8Bom + csvContent;

    const fileName = salgDataType === 'transactionData'
      ? `Transaksjoner_${alfa}${artikkel}`
      : `Kundetopp100_${searchedKundenummer}`;

    const element = document.createElement('a');
    element.setAttribute(
      'href',
      `data:text/csv;charset=utf-8,${encodeURIComponent(fullCsvContent)}`
    );
    element.setAttribute('download', `${fileName}.csv`);

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <Container sx={{ width: '1600px', marginTop: '15px !important' }}>
        <Heading title="Varestatestikker" color="White" />
      <Paper elevation={4} sx={{ padding: 2, backgroundColor: 'rgba(255, 255, 255, 0.7)' }}>

        <SearchFields
          alfa={alfa}
          artikkel={artikkel}
          varegruppe={varegruppe}
          kundenummerInput={kundenummerInput}
          setAlfa={setAlfa}
          setArtikkel={setArtikkel}
          setVaregruppe={setVaregruppe}
          setKundenummerInput={setKundenummerInput}
          suggestedCustomers={KundeData || []}
          handleCustomerSelect={(customer) => {
            setKundenummerInput(customer.id);
            setSearchedKundenummer(customer.id);
             setSuggestedCustomers([]);        

          }}
          kundenummerRef={kundenummerRef}
     
        />
        <Header
          onFetchTransactions={fetchTransactions}
          onFetchSalg={handleHentSalgClick}
          onFetchKundeTopp50={handleKundeTopp50Click}
          onhandleExport={exportToCSV}
          isExportDisabled={isExportDisabled}
        />
        {showYearOptions && (
          <YearOptions
            selectedYears={selectedYears}
            handleYearChange={(year) =>
              setSelectedYears((prev) => ({ ...prev, [year]: !prev[year] }))
            }
          />
        )}
        {(error || reduxError) && <Typography color="error">{error || reduxError}</Typography>}
        {/* Conditionally render VarerDetails only for "alle transaskjoner" and "Salg siste 4 �r" */}
        {(salgDataType === 'transactionData' || salgDataType === 'salgData') && (
          <VarerDetails varerdata={SalgVarer} sistesalg={sisteSalgData} />
        )}
        {/* Make YearlySales and GridView scrollable */}
        <Box style={{ maxHeight: '500px', overflowY: 'auto', overflowX: 'hidden', marginTop: '15px' }}>
          {/* Conditionally render YearlySales or GridView */}
          {!(salgDataType === 'customerData' || salgDataType === 'transactionData') ? (
            <YearlySales
              data={Salg}
              varerdata={SalgVarer}
              sistesalg={sisteSalgData}
            />
          ) : (
            // Conditionally render GridView only if there is data to display
            (salgDataType === 'customerData' && KundeSalg.length > 0) ||
            (salgDataType === 'transactionData' && Transaction.length > 0) ? (
              <GridView
                data={salgDataType === 'customerData' ? KundeSalg : Transaction}
                kundenummer={searchedKundenummer}
                dataType={salgDataType}
              />
            ) : null
          )}
          {/* Pagination Controls */}
          {(salgDataType === 'transactionData' || salgDataType === 'customerData') && 
          (salgDataType === 'transactionData' ? Transaction.length > 0 : KundeSalg.length > 0) && 
          hasNextPage && (
            <Box ml={2}>
              <PaginationControls
                hasNextPage={hasNextPage}
                handleLoadMore={handleLoadMoreTransactions}
              />
            </Box>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default Vars;
