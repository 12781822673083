import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SalgVarerData } from "../../../../models/NV01SALG";

interface VarerDetailsProps {
  varerdata: SalgVarerData[];
  sistesalg: string;
}

const VarerDetails: React.FC<VarerDetailsProps> = ({ varerdata, sistesalg }) => {
  return (
    <>
      {varerdata.map((item, index) => (
        <Accordion key={index} sx={{ marginBottom: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
        <Typography variant="h6" component="div">
  {`${item.alfa} ${item.artikkelnummer} ${item.navn} `}
</Typography>

          </AccordionSummary>
          <AccordionDetails>
            <Card sx={{ backgroundColor: "#f5f5f5" }}>
              <CardContent>
                <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }} color="black">
                      Pris
                    </Typography>
                    <Typography variant="body1">{item.utsalgspris}</Typography>
                  </Grid>
                  
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }} color="black">
                      Pristillegg
                    </Typography>
                    <Typography variant="body1">{item.pristillegg || "Ingen"}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }} color="black">
                      PristilleggType
                    </Typography>
                    <Typography variant="body1">{item.pristillegg_type || "Ingen"}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }} color="black">
                      Beholdning
                    </Typography>
                    <Typography variant="body1">{item.beholdning}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }} color="black">
                      Antall i restordre
                    </Typography>
                    <Typography variant="body1">{item.antall_rest}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }} color="black">
                      Antall i bestilling
                    </Typography>
                    <Typography variant="body1">{item.antall_ibest}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }} color="black">
                      Lev År/Mnd
                    </Typography>
                    <Typography variant="body1">{item.delivery}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }} color="black">
                      Siste tilgang
                    </Typography>
                    <Typography variant="body1">
                    {new Date(item.siste_tilgang).toLocaleDateString('no-NO', {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric'
})}

                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }} color="black">
                      Siste salg
                    </Typography>
                    <Typography variant="body1">{sistesalg}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }} color="black">
                      Siste prisendring
                    </Typography>
                    <Typography variant="body1">
                      {new Date(item.pris_endret).toLocaleDateString('no-NO', {
  day: '2-digit',
  month: '2-digit',
  year: '2-digit'
})
}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }} color="black">
                      Artikkel opprettet
                    </Typography>
                    <Typography variant="body1">{item.opprettet}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }} color="black">
                      Alternativ artikkel
                    </Typography>
                    <Typography variant="body1">{item.altnr ?? "Ingen alternativ artikkel"}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }} color="black">
                      Lokasjon
                    </Typography>
                    <Typography variant="body1">{item.lokasjon}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }} color="black">
                      Antall i FOR2
                    </Typography>
                    <Typography variant="body1">{item.for2?.antall ?? "N/A"}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }} color="black">
                      Reservert i FOR2
                    </Typography>
                    <Typography variant="body1">{item.for2?.reservert ?? "N/A"}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }} color="black">
                      Sluppet forhåndsordre
                    </Typography>
                    <Typography variant="body1">{item.slupp_forhordre}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }} color="black">
                      Restet i varesett
                    </Typography>
                    <Typography variant="body1">{item.restet_varesett}</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default VarerDetails;
