import { TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import NV03Vare, { NV03Firma, NV03Headers } from '../../../../models/NV03Vare';
import * as actionsNV03 from '../../../../store/actions/indexNV03';
import { AppState } from '../../../../store/rootReducer';

const useStyles = makeStyles()((/*theme: Theme*/) => ({
  tableCell: {
    textTransform: 'capitalize',
    marginLeft: 0, padding: '6px 20px', textAlign: 'left', fontSize: 16, fontWeight: 600,
    backgroundColor: '#fff', color: '#575655', borderBottom: '1px solid #c4c4c4', //
    "& .MuiTableSortLabel-root:hover, .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon": {
      color: "#000"
    },
    // for active case hover color
    "& .MuiTableSortLabel-root.Mui-active:hover, .MuiTableSortLabel-root.Mui-active:hover .MuiTableSortLabel-icon": {
      color: "#000"
    },
    "& .MuiTableSortLabel-root.Mui-active, .MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon": {
      fontWeight: 600,
      color: "#d30535"
    }
  },
}),
);

export const headerListInitialState: NV03Headers[] = [
  { id: "alfa", numeric: false, label: "Alfa", width: '3%', order: 'desc', sortable: true, orderBy: false, filter: '', vis: 1 },
  { id: "artikkelNummer", numeric: false, label: "Artikkel", width: '5%', order: 'asc', sortable: true, orderBy: true, filter: '', vis: 1 },
  { id: "vareSpes", numeric: false, label: "Varebetegnelse", width: '25%', order: 'desc', sortable: true, orderBy: false, filter: '', vis: 1 },
  { id: "vareGruppe", numeric: false, label: "Varegruppe", width: '10%', order: 'desc', sortable: true, orderBy: false, filter: '', vis: 1 },
  { id: "utSalgPris", numeric: false, label: "Pris", width: '3%', order: 'desc', sortable: true, orderBy: false, filter: '', vis: 1 },
  { id: "veilPris", numeric: false, label: "Veil Pris", width: '3%', order: 'desc', sortable: true, orderBy: false, filter: '', vis: 1 },
  { id: "lokasjon", numeric: false, label: "Lokasjon", width: '15%', order: 'desc', sortable: true, orderBy: false, filter: '', vis: 1 },
  { id: "behold", numeric: false, label: "Beholdning", width: '10%', order: 'desc', sortable: true, orderBy: false, filter: '', vis: 1 },
  { id: "lk10", numeric: false, label: 'lk10', width: '5%', order: 'desc', sortable: false, orderBy: false, filter: '', vis: 0 },
  { id: "lk18", numeric: false, label: 'lk18', width: '5%', order: 'desc', sortable: true, orderBy: false, filter: '', vis: 0 },
  { id: "lk13", numeric: false, label: 'lk13', width: '5%', order: 'desc', sortable: true, orderBy: false, filter: '', vis: 0 },
  { id: "lk15", numeric: false, label: 'lk15', width: '5%', order: 'desc', sortable: true, orderBy: false, filter: '', vis: 0 },
  { id: "lk17", numeric: false, label: 'lk17', width: '5%', order: 'desc', sortable: true, orderBy: false, filter: '', vis: 0 },
  { id: "lk92", numeric: false, label: 'lk92', width: '5%', order: 'desc', sortable: true, orderBy: false, filter: '', vis: 0 },
  { id: "lk93", numeric: false, label: 'lk93', width: '5%', order: 'desc', sortable: true, orderBy: false, filter: '', vis: 0 },
  { id: "auto", numeric: false, label: "Auto", width: '5%', order: 'desc', sortable: true, orderBy: false, filter: '', vis: 1 },
  { id: "Merknad", numeric: false, label: "Merknad", width: '5%', order: 'desc', sortable: true, orderBy: false, filter: '', vis: 1 },
];


interface NV03TableHeadProps {
  onRequestSort: (property: string | NV03Headers[]) => void;
}

const NV03TableHead: React.FC<NV03TableHeadProps> = ({ onRequestSort }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const nV03Headers: NV03Headers[] = useSelector((state) => (state as AppState).NV03.nV03Headers);
  const lagerNavnFraAPI: NV03Firma = useSelector((state) => (state as AppState).NV03.nV03Firma);
  const isBeholdningChecked: boolean = useSelector((state) => (state as AppState).NV03.isBeholdningChecked);
  const isVeilPrisChecked: boolean = useSelector((state) => (state as AppState).NV03.isVeilPrisChecked);
  const isVisAlleLagerChecked: boolean = useSelector((state) => (state as AppState).NV03.isVisAlleLagerChecked);
  const page: NV03Vare[] = useSelector((state) => (state as AppState).NV03.page.content);
  const keys: string[] = ['lk10', 'lk18', 'lk13', 'lk15', 'lk17', 'lk92', 'lk93']

  //
  useEffect(() => {
    // If no Label is available for the lager, or no data in payload then hide the column
    if (lagerNavnFraAPI) {
      headerListInitialState.forEach(element => {
        for (const keyL in lagerNavnFraAPI) {
          if (element.id === keyL && lagerNavnFraAPI[element.id]?.length > 0) {
            element.vis = 1;
          }
        }
      });
      let i = 0;
      //
      for (const keyL in lagerNavnFraAPI) {
        if (keys.includes(keyL)) {
          let visible = 0;
          page.forEach(row => {

            i++;
            for (const keyRow in row) {
              // i++;
              if (keys.includes(keyRow) && keyL === keyRow) {
                if (row[keyRow] || row[keyRow] === '0') {
                  visible = 1;
                }
              }
            }
          });
          //
          headerListInitialState.forEach(element => {
            if (element.id === keyL) {
              element.vis = visible === 0 ? 0 : 1;
            }
          });
        }
      }
    }
    //
  }, [dispatch, isBeholdningChecked, isVeilPrisChecked, isVisAlleLagerChecked, lagerNavnFraAPI]);

  const handleClick = (property: NV03Headers) => {
    const filteredColumnHeaderList: NV03Headers[] = nV03Headers.map((row: NV03Headers) => {
      if (row.id === property.id) {
        return {
          ...row,
          orderBy: true,
          order: row.order === 'asc' ? 'desc' : 'asc',
        };
      }
      return {
        ...row,
        orderBy: false,
      };
    });
    //
    dispatch(actionsNV03.NV03UpdateColumnHeaderList(filteredColumnHeaderList));
    //
    if (onRequestSort) {
      onRequestSort(filteredColumnHeaderList);
    }
  };

  /*
  * Build the menu items parent nodes
  */
  const renderColumnHeader = () => {
    const JSXColumnHeader: JSX.Element[] = [];
    nV03Headers.forEach((row: NV03Headers) => {
      let label = row.label;
      switch (true) {
        case row.id == 'lk10':
          label = lagerNavnFraAPI.lk10?.trim().toLowerCase();
          break;
        case row.id == 'lk18':
          label = lagerNavnFraAPI.lk18?.trim().toLowerCase();
          break;
        case row.id == 'lk13':
          label = lagerNavnFraAPI.lk13?.trim().toLowerCase();
          break;
        case row.id == 'lk15':
          label = lagerNavnFraAPI.lk15?.trim().toLowerCase();
          break;
        case row.id == 'lk17':
          label = lagerNavnFraAPI.lk17?.trim().toLowerCase();
          break;
        case row.id == 'lk92':
          label = lagerNavnFraAPI.lk92?.trim().toLowerCase();
          break;
        case row.id == 'lk93':
          label = lagerNavnFraAPI.lk93?.trim().toLowerCase();
          break;
        default:
          break;
      }
      switch (true) {
        case !isBeholdningChecked && row.id === 'behold':
          break;
        case !isVeilPrisChecked && row.id === 'veilPris':
          break;
        case !isVisAlleLagerChecked
          && (row.id === 'lk10'
            || row.id === 'lk18'
            || row.id === 'lk13'
            || row.id === 'lk15'
            || row.id === 'lk17'
            || row.id === 'lk92'
            || row.id === 'lk93'):
          break
        default: {
          row.vis === 1 ? JSXColumnHeader.push(
            <TableCell
              className={classes.tableCell}
              key={row.id}
              align="left"
              padding="none"
            >
              <Tooltip
                title={"Sort  " + label}
                placement={row.numeric ? "bottom-end" : "bottom-start"}
                enterDelay={300}
              >
                <TableSortLabel
                  active={row.orderBy}
                  direction={row.order}
                  onClick={() => handleClick(row)}
                >{label}</TableSortLabel>
              </Tooltip>
            </TableCell>
          ) : null
        }
          break;
      }
    });
    //
    return JSXColumnHeader;
  };
  //
  return (
    <TableHead><TableRow>{renderColumnHeader()}</TableRow></TableHead>
  );
};

export default NV03TableHead;