import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

const Heading = ({ title, color }) => {
  return (
    <Paper elevation={3} sx={{ marginBottom: '20px', borderRadius: '5px' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '16px',
          backgroundColor: '#d30535',
          color: color === 'black' ? 'black' : 'white', // Default to white if not black
          borderRadius: '5px',
        }}
      >
        <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
      </Box>
    </Paper>
  );
};

export default Heading;
