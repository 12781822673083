import React, { useState } from "react";
import ButtonVitec from "../../../../components/ButtonHighlight";
import Typography from "@mui/material/Typography";

const Header = ({ onFetchTransactions, onFetchSalg, onFetchKundeTopp50,onhandleExport ,isExportDisabled}) => {
  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
    if (buttonType === "transactions") {
      onFetchTransactions();
    } else if (buttonType === "salg") {
      onFetchSalg();
    } else if (buttonType === "kundeTopp50") {
      onFetchKundeTopp50();
    }else if (buttonType === "export") {
      onhandleExport();
    }
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <Typography variant="h4" component="h1" gutterBottom>
        
      </Typography>

      {/* Button for "Alle transaksjoner" */}
      <ButtonVitec
        _type="submit"
        _fontsize="1em"
        _onclick={() => handleButtonClick("transactions")}
        _label="Alle transaksjoner"
        _width={250}
        _height={40}
        _padding={5}
        highlighted={activeButton === "transactions"} // Check if this button is active
      />

      {/* Button for "Salg siste 4 år" */}
      <ButtonVitec
        _type="submit"
        _fontsize="1em"
        _onclick={() => handleButtonClick("salg")}
        _label="Salg siste 4 år"
        _width={250}
        _height={40}
        _padding={5}
        highlighted={activeButton === "salg"} // Check if this button is active
      />

      {/* Button for "Kunde Topp 100" */}
      <ButtonVitec
        _type="submit"
        _fontsize="1em"
        _onclick={() => handleButtonClick("kundeTopp50")}
        _label="Kunde Topp 100"
        _width={250}
        _height={40}
        _padding={5}
        highlighted={activeButton === "kundeTopp50"} // Check if this button is active
      />
       <ButtonVitec
        _type="button"
        _label="Excel"
        _onclick={() => handleButtonClick("export")}
        _fontsize="1em"
        _width={250}
        _height={40}
        _padding={5}
        _margin={0}
        disabled={isExportDisabled}
      />
    </div>
  );
};

export default Header;
