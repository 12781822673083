import { Box } from "@mui/material";
import ButtonVitec from "../../../../components/ButtonVitec";

interface PaginationControlsProps {
  hasNextPage: boolean;
  handleLoadMore: () => void;

}

export const PaginationControls: React.FC<PaginationControlsProps> = ({
  hasNextPage,
  handleLoadMore,
 
}) => {
  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2}>
     
      {/* Next 100 Button */}
     
        <Box ml={2}> {/* Add spacing between buttons */}
          <ButtonVitec
            _type="button"
            _label="Neste side"
            _onclick={handleLoadMore}
            _fontsize="1em"
            _width={250}
            _height={40}
            _padding={5}
            _margin={0}
          />
        </Box>
    
    </Box>
  );
};

