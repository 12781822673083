import jwtDecode from 'jwt-decode';
import { NavLink } from 'react-router-dom';
import SpanBr from '../components/SpanBr';
import MenuEl from '../models/MenuEl';
import MenuItem from '../models/MenuItem';
import MenuItemTarget from '../models/MenuItemTarget';
import UISize from '../models/UISize';

export default class Utils {
  //
  static C_ENV_DEV = 'development';
  static C_ENV_TEST = 'test';
  static C_ENV_PROD = 'production';
  static C_ENV_UTV = 'utv';
  static _I = 0;

  /*************************************************************************************** */
  /*************************************************************************************** */
  /*************************************************************************************** */
  /*                    LOG AND DISPLAY INFO DURING DEVELOPMENT                            */

  static IS_ENV_DEV(isDebugEnabled = false) {
    return Utils.getEnv.toString() === 'development' && isDebugEnabled;
  }

  /*************************************************************************************** */
  /*************************************************************************************** */
  /*************************************************************************************** */

  static get VERSION() {
    return process.env.REACT_APP_BUILD_VERSION;
  }

  static CURRENT_BREAKPOINT(innerWidth: number) {
    switch (true) {
      case innerWidth > -1 && innerWidth <= 801:
        return 'xss';
      case innerWidth > 801 && innerWidth <= 1367:
        return 'xs';
      case innerWidth > 1367 && innerWidth <= 1681:
        return 'sm';
      case innerWidth > 1681 && innerWidth < 1921:
        return 'md';
      case innerWidth > 1921 && innerWidth < 2561:
        return 'lg';
      case innerWidth > 2561 && innerWidth < 3841:
        return 'xl';
      case innerWidth > 3841:
        return 'xxl';
      default:
        return 'md';
    }
  }

  static get HOST_NAME() {
    return window.location.hostname; // '/autodata-web-test' //packageJson.homepage;
  }

  static get WEBSOCKET_ADDRESS() {
    return process.env.REACT_APP_WEBSOCKET; // '/autodata-web-test' //packageJson.homepage;
  }

  static get HOMEPAGE_CONTEXT() {
    return process.env.PUBLIC_URL; // '/autodata-web-test' //packageJson.homepage;
  }

  static get T_hauth() {
    const token = sessionStorage.getItem('token');
    return (jwtDecode(token || '') as any)._log;
  }

  static get T_tabId() {
    const token = sessionStorage.getItem('token');
    return token ? (jwtDecode(token || '') as any).tabId.toString() : undefined;
  }

  static get T_apiVersion() {
    const token = sessionStorage.getItem('token');
    return token ? (jwtDecode(token || '') as any).apiVersion.toString() : '';
  }

  static get T_QTR() {
    const token = sessionStorage.getItem('token');
    return token ? (jwtDecode(token || '') as any).QTR.toString() : '';
  }

  static get isTabletAndroid() {
    const token = sessionStorage.getItem('token');
    return token ? (jwtDecode(token || '') as any).tablet.toString() === 'android' ? true : false : false;
  }

  static get isTabletIpad() {
    const token = sessionStorage.getItem('token');
    return token ? (jwtDecode(token || '') as any).tablet.toString() === 'ipad' ? true : false : false;
  }

  static get isDesktop() {
    const token = sessionStorage.getItem('token');
    return token ? (jwtDecode(token || '') as any).tablet.toString() === 'pc' ? true : false : false;
  }

  static get T_parentId() {
    const token = sessionStorage.getItem('token');
    return token ? (jwtDecode(token || '') as any).parent.toString() : undefined;
  }

  static get T_sub() {
    const token = sessionStorage.getItem('token');
    return token ? (jwtDecode(token || '') as any).sub.toString() : undefined;
  }

  static get T_displayName() {
    const token = sessionStorage.getItem('token');
    return token ? (jwtDecode(token || '') as any).displayName.toString() : undefined;
  }

  static get T_company() {
    const token = sessionStorage.getItem('token');
    return token ? (jwtDecode(token || '') as any).company.toString() : undefined;
  }

  static get T_roles() {
    const token = sessionStorage.getItem('token');
    return token ? (jwtDecode(token || '') as any).roles.toString() : undefined;
  }

  static get APP_TITLE() {
    return 'Vitec Autodata Web';
  }

  static get HEIRLOOM_SERVICE() {
    return process.env.REACT_APP_H_SERVICE;
  }

  static get VITEC_SERVICE() {
    return process.env.REACT_APP_J_SERVICE;
  }

  static get DOTNET_SERVICE() {
    return process.env.REACT_APP_C_SERVICE;
  }

  static get URL_ADDRESS() {
    return process.env.REACT_APP_NODEJS_PROXY;
  }

  static get NODE_ENV_TYPE() {
    return Utils.getEnv;
  }

  // Return a static incrementation of I accessible from the whole app.
  static get I() {
    return Utils._I++;
  }
  // ENV
  static get getEnv() {
    switch (process.env.NODE_ENV) {
      case Utils.C_ENV_PROD:
        return Utils.C_ENV_PROD;
      case Utils.C_ENV_TEST:
        return Utils.C_ENV_TEST;
      case Utils.C_ENV_DEV:
        return Utils.C_ENV_DEV;
      default:
        return Utils.C_ENV_UTV;
    }
  }

  // Fabrice - Temporary FLAT built-up - Awaiting for a proper API Json object
  static assembleMenuItemList = (menuItems: MenuItem[]) => {
    const uniqueItems: string[] = [];
    const menuTree: MenuEl[] = [];
    if (menuItems.length > 0) {
      menuItems.forEach((item: MenuItem) => {
        if (!uniqueItems.includes(item.item)) {
          uniqueItems.push(item.item);
          if (item.subitem !== '') menuTree.push(new MenuEl(item.item, item.role, '', item.item, item.heading, item.code
            , item.tilpasset, item.tilpassetBeta, item.kodeSynlig, item.labelSynlig, item.name, [], true));
          else
            menuTree.push(
              new MenuEl(item.item, item.role, item.item, item.item, '', item.code, item.tilpasset, item.tilpassetBeta, item.kodeSynlig, item.labelSynlig, item.name, [
                new MenuEl(item.item, item.role, item.item, item.item, item.heading, item.code, item.tilpasset, item.tilpassetBeta, item.kodeSynlig, item.labelSynlig, item.name, [
                  new MenuEl(item.item, item.role, item.item, item.item, item.heading, item.code, item.tilpasset, item.tilpassetBeta, item.kodeSynlig, item.labelSynlig, item.name, []),
                ]),
              ])
            );
        }

        if (item.subitem === '') {
          const bob: MenuEl = menuTree.filter((obj: MenuEl) => {
            return obj.name === item.item;
          })[0];
          bob.nodes[0].nodes[0].nodes.push(new MenuEl(item.name, item.role, '', item.item, item.heading, item.code, item.tilpasset, item.tilpassetBeta, item.kodeSynlig, item.labelSynlig, item.name));
        }
      });

      // Conditions when there are no subItem / nodes

      const uniqueSubItems: string[] = [];
      menuItems.forEach((subItem: MenuItem) => {
        if (!uniqueSubItems.includes(subItem.subitem)) {
          if (subItem.subitem !== '') {
            uniqueSubItems.push(subItem.subitem);
            let rootName = '';
            menuTree
              .filter((obj: MenuEl) => {
                if (obj.name === subItem.item) rootName = obj.rootName;
                return obj.name === subItem.item;
              })[0]
              .nodes.push(new MenuEl(subItem.subitem, subItem.role, subItem.item, rootName, subItem.heading, subItem.code
                , subItem.tilpasset, subItem.tilpassetBeta, subItem.kodeSynlig, subItem.labelSynlig, subItem.name));
            const uniqueHeading: string[] = [];
            menuItems.forEach((el) => {
              if (el.item === subItem.item && el.subitem === subItem.subitem && !uniqueHeading.includes(el.heading)) {
                uniqueHeading.push(el.heading);
                menuTree
                  .filter((obj: MenuEl) => {
                    return obj.name === el.item;
                  })[0]
                  .nodes.filter((obi: MenuEl) => {
                    return obi.name === subItem.subitem;
                  })[0]
                  .nodes.push(new MenuEl(el.heading, el.role, subItem.subitem, rootName, el.heading, el.code, el.tilpasset, el.tilpassetBeta
                    , el.kodeSynlig, el.labelSynlig, el.name));
              }
            });
          }
        }
      });

      //
      menuItems.forEach((item: MenuItem) => {
        const selectLevel2 = menuTree
          .filter((obj: MenuEl) => {
            return obj.name === item.item;
          })[0]
          .nodes.filter((obi: MenuEl) => {
            return obi.name === item.subitem;
          })[0];

        if (selectLevel2) {
          let rootName = '';
          selectLevel2.nodes
            .filter((obc: MenuEl) => {
              if (obc.heading === item.heading) rootName = obc.rootName;
              return obc.heading === item.heading;
            })[0]
            .nodes.push(new MenuEl(item.heading, item.role, item.subitem, rootName, item.heading, item.code, item.tilpasset, item.tilpassetBeta, item.kodeSynlig, item.labelSynlig, item.name));
        }
      });
    }
    return menuTree;
  };

  static getTransactionsRole(menuItems, val) {
    let role: any = undefined;
    if (menuItems && menuItems.length > 0) {
      menuItems.forEach((item: MenuEl) => {
        item.nodes.forEach((subItem: MenuEl) => {
          subItem.nodes.forEach((last: MenuEl) => {
            last.nodes.forEach((lst: MenuEl) => {
              if (lst.code === val) {
                role = lst.role;
              }
            });
          });
        });
      });
    }
    return role;
  }


  static getTransactionsMenuItem(menuItems, val) {
    let mwe: any = {}
    menuItems.forEach((item: MenuEl) => {
      item.nodes.forEach((subItem: MenuEl) => {
        subItem.nodes.forEach((last: MenuEl) => {
          last.nodes.forEach((lst: MenuEl) => {
            if (lst.code === val) {
              mwe = lst;
            }
          });
        });
      });
    });
    return mwe;
  }

  // ENV
  static getFormattedDate(date: Date) {
    return date.getFullYear() + '-' + (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
  }

  static get devAddon() {
    if (Utils.IS_ENV_DEV(true)) {
      return (
        <span>
          <a href={Utils.HEIRLOOM_SERVICE + '/menu.html'}>menu.html</a>
        </span>
      );
    }
    return null;
  }

  static log(label = '', val: any = undefined) {
    if (Utils.getEnv === 'development') {
      console.log(Utils.getEnv + ' | ' + label + ': ', val ? val : '');
    }
  }

  // REDUCER update store
  static updateStore = (oldObject, updatedProperties) => {
    return {
      ...oldObject,
      ...updatedProperties,
    };
  };

  /*
   * Mockup
   *
   */
  static feedBackTextElement = (val: string) => {
    // Lookup for msg sent from API
    switch (true) {
      case val.indexOf('token_expired') !== -1:
        return [
          <SpanBr key="fb0">
            Token er ugyldig. Få tilsendt en ny ved å trykke på denne
            <br />
            <br />
            <NavLink to={Utils.HEIRLOOM_SERVICE + '/passwordlinkrecovery'}>Linken</NavLink>
            <br />
          </SpanBr>,
        ];

      case val.indexOf('invalid_token') !== -1:
        return [
          <SpanBr key="fb0">
            Token er ugyldig. Få tilsendt en ny ved å trykke på denne
            <br />
            <br />
            <NavLink to={Utils.HEIRLOOM_SERVICE + '/passwordlinkrecovery'}>Linken</NavLink>
            <br />
          </SpanBr>,
        ];

      case val.indexOf('not_authorized') !== -1:
        return [
          <SpanBr key="fb0">
            En feil oppstod ved endring av passord!
            <br />
            Du er ikke autorisert for å endre passordet på brukerenIkke autorisert.
          </SpanBr>,
        ];

      case val.indexOf('error code 53') !== -1:
        return [
          <SpanBr key="fb0">
            En feil oppstod ved endring av passord!
            <br />
            Passordet oppfyller ikke passordpolicyen.
          </SpanBr>,
        ];

      case val.indexOf('pass_updated') !== -1:
        return [<SpanBr key="fb0">Passordet har blitt oppdatert!</SpanBr>];

      case val.indexOf('user_mot_found') !== -1:
        return [<SpanBr key="fb0">Bruker ikke funnet.</SpanBr>];

      default:
        return [<SpanBr key="fb1" text={val.toString()} />];
    }
  };

  /*
   * Build a Json Obj from and URL query
   *
   */

  static getJsonFromUrl = (url: string) => {
    const query = url.substr(1);
    const result = {};
    query.split('&').forEach(function (part) {
      const item = part.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  };

  // Use local storage for messaging. Set message in local storage and clear it right away
  // This is a safe way how to communicate with other tabs while not leaving any traces
  static message_broadcast(message, delay = 0) {
    setTimeout(() => {
      localStorage.setItem('message', JSON.stringify(message /*, Utils.replacer*/));
      localStorage.removeItem('message');
    }, delay);
  }

  // Get innerWidth and innerHeight of the browser windows
  static getWindowDimensions() {
    const { innerWidth: width, innerHeight: height, pageYOffset } = window; // Utils.log(new UISize(width, height), ' dim: ');
    return new UISize(width, height, pageYOffset);
  }

  // Get innerWidth and innerHeight of the browser windows
  static getLocationPatchFromMenuItem(menuItemTarget?: MenuItemTarget, transacId = '', menuItems: any = undefined) {
    if (menuItemTarget && menuItemTarget?.rootItem !== '') {
      return (<div style={{ display: 'flex', overflow: 'hidden', textOverflow: 'ellipsis' }}>{menuItemTarget.rootItem} {menuItemTarget.item !== '' ? ' > '
        + menuItemTarget.item : null} {menuItemTarget.subitem !== menuItemTarget.title ? ' > '
          + menuItemTarget.subitem : null} {' > ' + (menuItemTarget.kodeSynlig === 1 ? menuItemTarget.code : '')} {menuItemTarget.title}
      </div>)
    } else if (transacId !== '' && menuItems?.length > 0) {
      const mwe: MenuEl = Utils.getTransactionsMenuItem(menuItems, transacId);
      return (<>{mwe.rootName} {mwe.parentName !== '' ? ' > '
        + mwe.parentName : null} {mwe.heading !== '' ? ' > '
          + mwe.heading : null} {' > ' + transacId} {' - ' + mwe.name}
      </>)

    }
    return undefined;
  }


  // Get innerWidth and innerHeight of the browser windows
  static setLocationPath(el: any) {
    return (<div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{el}
    </div>)
  }



  // Utils to limit repetitions
  static debounce(func, ms = 50) {
    let timer;
    return function (event) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(func, ms, event);
    };
  }



  static isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  static isAModalCall(val = '') {
    return val.includes('htmlMsg=');
  }

  static ldapToJS(n) {
    // Longer, equivalent to short version
    // return new Date(n/1e4 + new Date(Date.UTC(1601,0,1)).getTime());
    // Shorter, more efficient. Uses time value for 1601-01-01 UTC
    return new Date(n / 1e4 - 1.16444736e13);
  }

  static compareByNameFilter(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  static getTextSelection(el) {
    let start = 0,
      end = 0,
      normalizedValue,
      range,
      textInputRange,
      len,
      endRange;
    //
    if (typeof el.selectionStart === 'number' && typeof el.selectionEnd === 'number') {
      start = el.selectionStart;
      end = el.selectionEnd;
    } else {
      range = (document as any).selection.createRange();

      if (range && range.parentElement() === el) {
        len = el.value.length;
        normalizedValue = el.value.replace(/\r\n/g, '\n');

        // Create a working TextRange that lives only in the input
        textInputRange = el.createTextRange();
        textInputRange.moveToBookmark(range.getBookmark());

        // Check if the start and end of the selection are at the very end
        // of the input, since moveStart/moveEnd doesn't return what we want
        // in those cases
        endRange = el.createTextRange();
        endRange.collapse(false);

        if (textInputRange.compareEndPoints('StartToEnd', endRange) > -1) {
          start = end = len;
        } else {
          start = -textInputRange.moveStart('character', -len);
          start += normalizedValue.slice(0, start).split('\n').length - 1;

          if (textInputRange.compareEndPoints('EndToEnd', endRange) > -1) {
            end = len;
          } else {
            end = -textInputRange.moveEnd('character', -len);
            end += normalizedValue.slice(0, end).split('\n').length - 1;
          }
        }
      }
    }
    return { start, end };
  }

  static preventDefault(e) {
    e.preventDefault();
  }

  static giveFocusToSearchField() {
    // CTRL + S
    document.getElementById('code-select')?.focus();
  }

  static removeFocusToSearchField() {
    document.getElementById('code-select')?.blur();
  }

  static desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  static asc(a, b, orderBy) {
    if (b[orderBy] > a[orderBy]) {
      return -1;
    }
    if (b[orderBy] < a[orderBy]) {
      return 1;
    }
    return 0;
  }

  static stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  static getSorting(order, orderBy) {
    switch (true) {
      case order === "desc":
        return order === "desc"
          ? (a, b) => Utils.desc(a, b, orderBy)
          : (a, b) => -Utils.desc(a, b, orderBy);
      case order === "asc":
        return order === "asc"
          ? (a, b) => Utils.asc(a, b, orderBy)
          : (a, b) => - Utils.asc(a, b, orderBy);
      default:
        return order === "asc"
          ? (a, b) => Utils.asc(a, b, orderBy)
          : (a, b) => - Utils.asc(a, b, orderBy);
    }
  }


  static formatter(val) {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: "code",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(val).replace('EUR', '');
  }

  static getPercentAB(numA: number, numB: number) {
    let perCent = 0.0;
    //
    if (numB < 0 && numA > 0) {
      perCent = (numA + (numB * -1)) / numB * 100;
      // perCent = (numA - numB) / numA * 100;
    } else if (numA < 0 && numB > 0) {
      perCent = (numB + (numA * -1)) / numA * 100;
      // perCent = (numA - numB) / numA * 100;
    } else if (numA > numB) {
      perCent = (numA - numB) / numA * 100;
    } else
      perCent = (numA - numB) / numB * 100;
    //
    return Math.round(perCent * 100) / 100;
  }
}

export function debounce(func: any, wait: number, immediate: boolean) {
  let timeout: number | undefined;

  return function executedFunction(_this: any) {
    const context: any = _this;
    const args = arguments; // eslint-disable-line
    const later = () => {
      timeout = undefined;
      if (!immediate) {
        func.apply(context, [args]);
      }
    };
    const callNow: boolean = immediate && !timeout;
    clearTimeout(timeout);
    timeout = window.setTimeout(later, wait);
    if (callNow) {
      func.apply(context, [args]);
    }
  };
}

export function throttle(fn: any, wait: number) {
  let shouldWait = false;

  return function () {
    if (!shouldWait) {
      fn([arguments]); // eslint-disable-line
      shouldWait = true;
      setTimeout(() => (shouldWait = false), wait);
    }
  };
}
