import React from "react";
import VarerDetails from "./components/VarerDetail";
import YearlySalesTable from "./components/YearlySales";

import { SalgVarerData,YearlySalesData } from "../../../models/NV01SALG";

interface YearlySalesProps {
  data: YearlySalesData[];
  sistesalg: string; // Ensure sistesalg is included in the props
  varerdata: SalgVarerData[];
}

const YearlySales: React.FC<YearlySalesProps> = ({
  data,
  sistesalg,
  varerdata,
}) => {
 
  return (
    <div>   
     <YearlySalesTable data={data} /> 
    </div>
  );
};

export default YearlySales;
