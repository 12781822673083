import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  Pagination,
  PaginationItem,
  Paper,
  Table, 
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { AppState } from '../../../store/rootReducer';
import * as actionsNO01 from '../../../store/actions/indexNO01'; // Update action imports for NO01
import UISize from '../../../models/UISize';
import { TradingPartner } from '../../../models/NO01Orku';
import FilterTextField from './FilterTextField';
import ButtonVitec from '../../../components/ButtonHighlight';
import Heading from './Heading';
import CSharpUtils from '../../../felles/CSharpUtils';
// Define the styles for the component
const useStyles = makeStyles()(() => ({
  root: { margin: 20, fontSize: '1em !important', color: '#fff' },
  container: {
    height: `calc(100vh - 82px)`,
    border: 'none',
  },
}));

const Orku = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  // Fetch screen size from Redux
  const screenSize: UISize = useSelector((state: AppState) => state.ui.screenSize);

  // Fetch data from Redux NO01 state
  const orkuData = useSelector((state: AppState) => state.NO01);
  const loading = useSelector((state: AppState) => state.NO01.isFetching);

  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(screenSize.width < 1200 ? 500 : 1000);
  const [filterKundenummer, setFilterKundenummer] = useState<string>('');
  const [filterVAD, setFilterVAD] = useState<string>('');
  const [filterKundenavn, setFilterKundenavn] = useState<string>('');

  const [showFilterKundenummer, setShowFilterKundenummer] = useState<boolean>(false);
  const [showFilterVAD, setShowFilterVAD] = useState<boolean>(false);
  const [showFilterKundenavn, setShowFilterKundenavn] = useState<boolean>(false);

  const tableRef = useRef<HTMLDivElement>(null);



  // Fetch data from Redux on mount
  useEffect(() => {
    dispatch(actionsNO01.fetchTradingPartnersRequest(CSharpUtils.T_companyID));
  }, [dispatch]);

  // Handle outside clicks to close filters
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (tableRef.current && !tableRef.current.contains(event.target as Node)) {
        setShowFilterKundenummer(false);
        setShowFilterVAD(false);
        setShowFilterKundenavn(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  // Handle changes in screen size to adjust rowsPerPage
  useEffect(() => {
    setRowsPerPage(screenSize.width < 1200 ? 500 : 1000);
  }, [screenSize.width]);

  const handleKeyPress = (event) => {
    const { key } = event;
    if (key === 'Enter') {
      console.log('Enter key pressed');
    }
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const formatNumber = (num: number): string => {
    const formattedAmount = Math.floor(num).toLocaleString('no-NO', {});
    return formattedAmount.replace(/\s/g, '.');
  };

  // Filter the data
    const filteredData = orkuData.tradingPartners.filter((row) => {
    const matchKundenummer = row.customerNumber.toString().includes(filterKundenummer);
    const matchVAD = row.companyId.toLowerCase().includes(filterVAD.toLowerCase());
    const matchKundenavn = row.customerName.toLowerCase().includes(filterKundenavn.toLowerCase());

    return matchKundenummer && matchVAD && matchKundenavn;
  });

  // Slice data based on the page and rowsPerPage
  const displayedData = filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  // Function to export data to CSV
  const exportToCSV = () => {
    if (!filteredData || filteredData.length === 0) {
      alert('No data available to export.');
      return;
    }

    const headers = ['Kundenummer', 'Firma-ID', 'Kundenavn', 'Kjøp hittil i år'];
    const csvContent = [
      headers.join(';'),
      ...filteredData.map((row) => {
        const rowData = [row.customerNumber, row.companyId, row.customerName, formatNumber(row.ytdSales)];
        return rowData.join(';');
      }),
    ].join('\n');

    const fileName = `Kundermeddirektebestilling`;
    const csvWithBOM = `\uFEFF${csvContent}`;

    const element = document.createElement('a');
    element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(csvWithBOM)}`);
    element.setAttribute('download', `${fileName}.csv`);

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <div className={classes.root} onKeyUp={handleKeyPress}>
      <Paper elevation={0} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }}>
        <Grid container className={classes.container} alignItems="center" justifyContent="center">
          <Box sx={{ marginTop: '5px', display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ width: '900px' }}>
              {/* Header Section */}
              <Heading title="Kunder med direktebestilling" color="white" />

              {/* Table for displaying data */}
              <Paper sx={{ padding: '5px', borderRadius: '5px', marginBottom: '20px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}>
                  {/* Export Button */}
                  <ButtonVitec
                    _type="button"
                    _label="Excel"
                    _onclick={exportToCSV}
                    _fontsize="1em"
                    _width={150}
                    _height={35}
                    _padding={5}
                    _margin={0}
                    sx={{
                      backgroundColor: '#ffffff',
                      color: '#d30535',
                      fontWeight: 'bold',
                      ':hover': {
                        backgroundColor: '#e6e6e6',
                      },
                      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    }}
                  />
                </Box>

                <TableContainer
                  sx={{
                    height: '60vh',
                    overflowY: 'auto',
                    borderRadius: '5px',
                  }}
                  ref={tableRef}
                >
                  <Table stickyHeader aria-label="Orku data table">
                    <TableHead>
                      <TableRow>
                        {/* Kundenummer Filter */}
                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                          <FilterTextField
                            label="Kundenummer"
                            value={filterKundenummer}
                            onChange={(e) => setFilterKundenummer(e.target.value)}
                            showFilter={showFilterKundenummer}
                            setShowFilter={setShowFilterKundenummer}
                          />
                        </TableCell>

                        {/* VAD Filter */}
                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                          <FilterTextField
                            label="AD"
                            value={filterVAD}
                            onChange={(e) => setFilterVAD(e.target.value)}
                            showFilter={showFilterVAD}
                            setShowFilter={setShowFilterVAD}
                          />
                        </TableCell>

                        {/* Kundenavn Filter */}
                        <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                          <FilterTextField
                            label="Kundenavn"
                            value={filterKundenavn}
                            onChange={(e) => setFilterKundenavn(e.target.value)}
                            showFilter={showFilterKundenavn}
                            setShowFilter={setShowFilterKundenavn}
                          />
                        </TableCell>
                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                          Kjøp hittil i år
                        </TableCell>
                        {/* Empty Cell */}
                        <TableCell align="right" sx={{ fontWeight: 'bold' }}></TableCell>
                      </TableRow>
                    </TableHead>

                    {/* Table Body */}
                    <TableBody>
                      {loading ? (
                        <TableRow>
                          <TableCell colSpan={4} align="center">
                            <CircularProgress size={24} />
                          </TableCell>
                        </TableRow>
                      ) : displayedData.length > 0 ? (
                        displayedData.map((row) => (
                          <TableRow key={row.customerNumber + row.companyId}>
                            <TableCell align="left">{row.customerNumber}</TableCell>
                            <TableCell align="left">{row.companyId}</TableCell>
                            <TableCell align="left">{row.customerName}</TableCell>
                            <TableCell align="right">{formatNumber(row.ytdSales)}</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4} align="center">
                            No data available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* Pagination */}
                <Box display="flex" justifyContent="center" marginTop={2}>
                  <Pagination
                    count={Math.ceil(filteredData.length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                    renderItem={(item) => <PaginationItem sx={{ fontSize: '1.2em' }} {...item} />}
                  />
                </Box>
              </Paper>
            </Box>
          </Box>
        </Grid>
      </Paper>
    </div>
  );
};

export default Orku;
