import React, { useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  IconButton,
  InputAdornment,
  Box,
} from "@mui/material";
import { FilterAlt } from "@mui/icons-material";
import {
  YearlySalesData,
  SalgVarerData,
  KundeSalgData,
  TransactionData,
} from "../../../models/NV01SALG";

interface GridViewProps {
  data: (KundeSalgData | TransactionData | YearlySalesData)[];
  kundenummer?: string;
  dataType: string;
}

const GridView: React.FC<GridViewProps> = ({ data, kundenummer, dataType }) => {
  const [filters, setFilters] = useState<{ [key: string]: string }>({});
  const [filterColumn, setFilterColumn] = useState<string | null>(null);

  const handleFilterChange = (columnName: string, value: string) => {
    setFilters({ ...filters, [columnName]: value });
  };

  const applyFilters = (
    item: KundeSalgData | TransactionData | YearlySalesData
  ) => {
    return Object.keys(filters).every((key) => {
      const value = item[key as keyof (KundeSalgData | TransactionData | YearlySalesData)]
        ?.toString()
        .toLowerCase();

      if (!value) {
        return false;
      }

      // Specific filtering for "ref_number" column
      if (key === "ref_number") {
        return value.startsWith(filters[key].toLowerCase());
      }

      return value.includes(filters[key].toLowerCase());
    });
  };

  const handleFilterClick = (columnName: string) => {
    if (filterColumn === columnName) {
      setFilters((prevFilters) => {
        const updatedFilters = { ...prevFilters };
        delete updatedFilters[columnName];
        return updatedFilters;
      });
      setFilterColumn(null);
    } else {
      setFilterColumn(columnName);
    }
  };

  // Define column mappings based on data type
  const columnDefinitions: { [key: string]: { label: string; field: string }[] } = {
    customerData: [
      { label: "Kundenummer", field: "kundenummer" },
      { label: "Antall", field: "count" },
      { label: "Alfa", field: "alpha" },
      { label: "Artikkel", field: "item_number" },
      { label: "Varebetegnelse", field: "description" },
      { label: "Varegruppe", field: "article_group" },
    ],
    transactionData: [
      { label: "Dato", field: "date" },
      { label: "Tid", field: "time" },
      { label: "Tekst", field: "routine" },
      { label: "Ref.nr", field: "ref_number" },
      { label: "Kundenr.", field: "customer" },
      { label: "Antall", field: "count" },
      { label: "Ident", field: "user" },
      { label: "Sign", field: "init" },
      { label: "Lager", field: "lk_code" },
      { label: "Lokasjon", field: "location" },
    ],
    // Add other data type definitions here
  };

  const columns = columnDefinitions[dataType] || [];

  return (
    <Paper style={{ width: "100%" }}>
      {/* Table Header */}
      <TableContainer style={{ maxHeight: '410px', overflowY: 'auto' }}>
      <Table stickyHeader >
        <TableHead>
          <TableRow>
            {columns.map((col) => (
              <TableCell
                key={col.field}
                style={{ fontWeight: "bold" }}
                padding="normal"
                sx={{ padding: '12px 16px' }} // Increase padding for height
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {col.label}
                  <IconButton
                    size="small"
                    onClick={() => handleFilterClick(col.field)}
                  >
                    <FilterAlt />
                  </IconButton>
                </Box>
                {/* Filter Input Field */}
                {filterColumn === col.field && (
                  <TextField
                    size="small"
                    variant="outlined"
                    value={filters[col.field] || ""}
                    onChange={(e) =>
                      handleFilterChange(col.field, e.target.value)
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => handleFilterClick(col.field)}
                          >
                            <FilterAlt />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{ marginTop: 1 }}
                  />
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {/* Scrollable Table Body */}
        <TableBody>
          {data.filter(applyFilters).map((item, index) => (
            <TableRow
              key={index}
              sx={{ height: '45px' }} // Set row height
            >
              {columns.map((col) => (
                <TableCell
                  key={col.field}
                  padding="normal"
                  sx={{ padding: '12px 16px' }} // Increase padding for height
                >
                  {/* Render customer number separately if present */}
                  {col.field === "kundenummer"
                    ? kundenummer
                    : (item as any)[col.field]?.toString()}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
    </Paper>
  );
};

export default GridView;
